.App {
}

.Nav {
  border-bottom: solid rgba(0,0,0,.2) 1px;
  padding: 1rem 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  display: flex;
  gap: 0.5rem;

}

.DrawerToolbar {
  display: flex;
  flex-direction: row-reverse;
  padding: 1em 0.5em;
  justify-content: space-between;
}

.Content {
  width: 50%;
  margin: auto;
  margin-top: 1em;
}

.Title {
  font-size: xx-large;
}

.rewrite-container > div {
  margin-top: 1em;
  background-color: #f2f2f2;
  padding: 1em;
}

.copy-button {
  color: #f2f2f2 !important;
  padding: 0.5em !important;
  background-color: #1976d2 !important;
  border-radius: 30;
}

.copy-button:hover {
  background-color: #1382f1 !important;
}

@media only screen and (orientation: portrait) {
  .Content {
    width: 95%;
  }
}